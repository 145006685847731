export enum ApiList {
  SistemaPagos = 0,
  Geografia = 1,
  GobiernoChih = 2,
  Core = 3,
}

export function getUrl(api: ApiList, mode: string): string {
  switch (api) {
    case ApiList.SistemaPagos:
      return mode == "production"
        ? "https://apis.pagosdigitales.com/SistemaPagos/V2/"
        : "https://dev.pagosdigitales.com/Apis/SistemaPagos/V1/";

    case ApiList.GobiernoChih:
      return mode == "production"
        ? "https://apis.pagosdigitales.com/GobiernoChihuahua/V1/"
        : "https://dev.pagosdigitales.com/Apis/GobiernoChihuahua/V1/";

    case ApiList.Core:
      return mode == "production"
        ? "https://azure.pagosdigitales.com/apis/core/"
        : "https://azure.dev.pagosdigitales.com/apis/core/";
    default:
      return "";
  }
}
