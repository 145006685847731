import { Module, VuexModule, getModule, Mutation, Action } from "vuex-module-decorators";
import store from "..";
import { Kiosko, Servicio, Empresa, Municipio } from "apd.models";
import { ApiErrorResponse, ApiGeografia, ApiSistemaPagos, AxiosHttpClient, ApiList, getUrl } from "apd.apiconnectors";
import errorSnackbar from "./errorSnackbar";

@Module({
  namespaced: true,
  name: "Dropdowns",
  store,
  dynamic: true,
})
class DropdownsModule extends VuexModule {
  public kioskos = [] as ApiErrorResponse | Kiosko[];
  public empresas = [] as Empresa[] | ApiErrorResponse;
  public servicios = [] as Servicio[] | ApiErrorResponse;
  public municipios = [] as Municipio[] | ApiErrorResponse;

  get ListaKioskos() {
    return () => {
      return this.kioskos;
    };
  }

  get ListaServicios() {
    return () => {
      return this.servicios;
    };
  }

  get ListaEmpresas() {
    return () => {
      return this.empresas;
    };
  }

  get ListaMunicipios() {
    return () => {
      return this.municipios;
    };
  }

  @Action({ rawError: true })
  public async GetKioskos() {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    client.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSistemaPagos = new ApiSistemaPagos(client);
    const kioskosReporte = [] as Kiosko[];

    await apiSistemaPagos.GetCajeros().then((kioskos) => {
      (kioskos as Kiosko[]).forEach((o) => {
        kioskosReporte.push(o);
      });

      this.SetKioskos(kioskosReporte);
    });
  }

  @Action({ rawError: true })
  public async GetKioskosMunicipio(municipios: number[]) {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    client.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSistemaPagos = new ApiSistemaPagos(client);

    await apiSistemaPagos.GetCajeros().then((kioskos) => {
      const kioskosReporte = [] as Kiosko[];
      (kioskos as Kiosko[]).forEach((o) => {
        if (municipios.length > 0) {
          if (
            municipios.includes(o.idMunicipio) &&
            !o.nombreReportes.startsWith("Pd") &&
            !o.nombreReportes.startsWith("PD") &&
            /*
            o.nombreReportes != "PD.com" &&
            o.nombreReportes != "PdApp" &&
            o.nombreReportes != "Pd.comJmasJuarez" &&
            */
            !o.nombrePublico.startsWith("CajaGeneral")
          ) {
            kioskosReporte.push(o);
          }
          if (
            municipios.includes(0) &&
            (o.nombreReportes.startsWith("Pd") || (o.nombreReportes.startsWith("PD") && !o.nombre.endsWith("Ext")))
          ) {
            kioskosReporte.push(o);
          }
          if (municipios.includes(-1) && o.nombrePublico.startsWith("CajaGeneral")) {
            kioskosReporte.push(o);
          }
        } else {
          kioskosReporte.push(o);
        }
      });
      this.SetKioskos(kioskosReporte);
    });
  }

  @Action({ rawError: true })
  public async GetServicios() {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    client.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSistemaPagos = new ApiSistemaPagos(client);

    await apiSistemaPagos.GetServicios().then((servicios) => {
      this.SetServicios(servicios as Servicio[]);
    });
    return;
  }

  @Action({ rawError: true })
  public async GetEmpresas() {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    client.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSistemaPagos = new ApiSistemaPagos(client);

    await apiSistemaPagos.GetEmpresas().then((empresas) => {
      this.SetEmpresas(empresas as Empresa[]);
    });

    return;
  }

  @Action({ rawError: true })
  public async GetMunicipios() {
    const clientSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    clientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSistemaPagos = new ApiSistemaPagos(clientSP);

    const client = new AxiosHttpClient(getUrl(ApiList.Geografia, this.context.rootGetters.appMode));
    client.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiGeografia = new ApiGeografia(client);

    let ubicaciones = [] as number[];

    await apiSistemaPagos
      .GetUbicacionesCajeros()
      .then((e) => {
        ubicaciones = e as number[];
      })
      .catch((e) => {
        const err = e as ApiErrorResponse;
        errorSnackbar.Show(err.apiError.mensajeUsuario);
      });

    await apiGeografia
      .ConsultarMunicipios(ubicaciones)
      .then((e) => {
        const municipios = e as Municipio[];
        this.SetMunicipios(municipios);
      })
      .catch((e) => {
        const err = e as ApiErrorResponse;
        errorSnackbar.Show(err.apiError.mensajeUsuario);
      });
  }

  @Mutation
  private SetKioskos(kio: Kiosko[] | ApiErrorResponse) {
    this.kioskos = kio;
  }

  @Mutation
  private SetServicios(ser: Servicio[] | ApiErrorResponse) {
    this.servicios = ser;
  }

  @Mutation
  private SetEmpresas(emp: Empresa[] | ApiErrorResponse) {
    this.empresas = emp;
  }

  @Mutation
  private SetMunicipios(mun: Municipio[] | ApiErrorResponse) {
    this.municipios = mun;
  }
}

export default getModule(DropdownsModule);
