import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/store/modules/spinnerModule";
import Snackbar from "@/store/modules/errorSnackbar";
import {
  mdiTableSearch,
  mdiCalendarExport,
  mdiClose,
  mdiCloseBox,
  mdiMinusBox,
  mdiCheckboxBlankOutline,
} from "@mdi/js";
import { TableHeader } from "@/models/vuetifyModels";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import { ApiSistemaPagos, ApiErrorResponse, AxiosHttpClient } from "apd.apiconnectors";
import Dropdowns from "@/store/modules/dropdownModule";
import { getUrl, ApiList } from "@/config/apiUrls";
import { Kiosko, Servicio, Filtro, ResumenFormaPago, FormaPago, Empresa, Municipio } from "apd.models";

Vue.component("DownloadExcel", JsonExcel);

@Component({
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    toDate(value: string): string {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return "sin registro";
      }
    },
  },
})
@Component
export default class ResumenPagosComponent extends Vue {
  public IconTSearch: string = mdiTableSearch;
  public IconExport: string = mdiCalendarExport;
  public IconClose: string = mdiClose;
  public IconCloseCh: string = mdiCloseBox;
  public IconMinusCh: string = mdiMinusBox;
  public IconCheckbox: string = mdiCheckboxBlankOutline;
  public dialog = false;
  public tzoffset = new Date().getTimezoneOffset() * 60000;
  public date = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public date2 = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public menu = false;
  public menu2 = false;
  public mensaje = "Todos los servicios";
  public agrupar = true;
  public agruparS = true;
  public soloQr = false;
  public comision = false;
  public formasPArr = [] as FormaPago[];
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public json_meta = [
    [
      {
        key: "charset",
        value: "utf-8",
      },
    ],
  ];

  public json_fields = {
    Servicio: "servicio",
    Medio: "medio",
    PagosTotal: "cantidadOperaciones",
    ImporteTotal: {
      field: "totalImporte",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    EfectivoCantidad: "cantidadEfectivo",
    TotalEfectivo: {
      field: "totalEfectivo",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    TarjetaCantidad: "cantidadTarjeta",
    TotalTarjeta: {
      field: "totalTarjeta",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    ChequesCantidad: "cantidadCheques",
    TotalCheques: {
      field: "totalCheques",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    CoDiCantidad: "cantidadCoDi",
    TotalCoDi: {
      field: "totalCoDi",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
  };

  public formDataVista: ResumenFormaPago[] = [];
  public empresasArr = [] as Empresa[];
  public serviciosArr = [] as Servicio[];
  public totales = {
    cantidadCheques: 0,
    cantidadComisiones: 0,
    cantidadEfectivo: 0,
    cantidadOperaciones: 0,
    cantidadTarjeta: 0,
    medio: "",
    servicio: "",
    totalCheques: 0,
    totalComisiones: 0,
    totalEfectivo: 0,
    totalImporte: 0,
    totalTarjeta: 0,
  } as ResumenFormaPago;

  public cajerosArr = [] as Kiosko[];
  public municipiosArr = [] as Municipio[];

  public formFiltro: Filtro = {
    rfc: undefined,
    email: undefined,
    referenciaPago: undefined,
    folioAutorizacion: undefined,
    folioAPD: undefined,
    telefono: undefined,
    limitar: undefined,
    autorizado: undefined,
    idEmpresa: undefined,
    idServicio: undefined,
    idFormaPago: undefined,
    municipios: [],
    estatusOperacion: [2, 3, 8],
    kioskos: [],
    servicios: [],
    fechaInicial: "",
    fechaFinal: "",
  };

  // Fecha inicial formateada
  public get getFechaInicial(): string {
    return this.formatDate(this.date);
  }

  // Fecha final formateada
  public get getFechaFinal(): string {
    return this.formatDate(this.date2);
  }

  // Metodo para formatear fecha para mostrar
  private formatDate(date: string): string {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  public async mounted(): Promise<void> {
    Spinner.Show();
    await this.LoadData();
    Spinner.Hide();
  }

  public get loading(): boolean {
    return Spinner.showLoader;
  }

  public get rules() {
    return this.$store.state.validationRules;
  }

  public servicios(): void {
    if ((Dropdowns.ListaServicios() as Servicio[]).length == 0) {
      Dropdowns.GetServicios()
        .then(() => {
          this.serviciosArr = Dropdowns.ListaServicios() as Servicio[];
          this.serviciosArr.sort((a, b) => a.nombreReportes.localeCompare(b.nombreReportes));
        })
        .catch((resp) => {
          const err: ApiErrorResponse = resp as ApiErrorResponse;
          Snackbar.Show(err.apiError.mensajeUsuario);
          Spinner.Hide();
        });
    } else {
      this.serviciosArr = Dropdowns.ListaServicios() as Servicio[];
    }
  }

  public formasPago(): void {
    const ob = {} as FormaPago;
    ob.id = "0";
    ob.nombre = "-Seleccionar Todos-";
    let value = [] as FormaPago[];
    this.clienteSP()
      .GetFormasPago()
      .then((resp) => {
        value = resp as FormaPago[];
        value = value.filter((payment) =>
          [
            "20544a93-7392-4743-aa52-c05eff03e684",
            "89560c97-9dfc-4372-9223-e88ee6f365c1",
            "b9bd8262-1567-4977-bb35-3f3d5f775e6c",
            "d0aad0d9-fab7-4c29-a590-5ea1ac1e8d1b",
          ].includes(payment.id),
        );
        this.formasPArr = value.sort((a, b) => a.nombre.localeCompare(b.nombre));
        this.formasPArr.unshift(ob);
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        Snackbar.Show(err.apiError.mensajeUsuario);
        Spinner.Hide();
      });
  }

  public get reporteNombre(): string {
    return "reporteResumenPagos" + this.date + "/" + this.date2 + ".xls";
  }

  public async buscar(): Promise<void> {
    Spinner.Show();
    this.formFiltro.fechaInicial = this.date;
    this.formFiltro.fechaFinal = this.date2 + " " + "23:59:59";
    this.formFiltro.descripcion = undefined;
    this.formFiltro.idEmpresa = undefined;
    if (this.formFiltro.idFormaPago && this.formFiltro.idFormaPago == "0") {
      this.formFiltro.idFormaPago = undefined;
    }

    if (this.soloQr) {
      this.formFiltro.descripcion = "QR Pagos Digitales";
    }

    await this.clienteSP()
      .GetResumenServicios(this.formFiltro)
      .then((resp) => {
        if (this.agrupar) {
          this.formDataVista = this.agruparCajeros(resp as ResumenFormaPago[]);
        } else {
          this.formDataVista = resp as ResumenFormaPago[];
        }

        if (this.agruparS) {
          this.agruparServicios();
        }

        this.dialog = true;
        this.getTotales();
      })
      .catch((err) => {
        const error = err as ApiErrorResponse;
        Snackbar.Show(error.apiError.mensajeUsuario);
      });
    Spinner.Hide();
  }

  public agruparCajeros(data: ResumenFormaPago[]): ResumenFormaPago[] {
    if (this.agrupar) {
      const agrupados = [] as ResumenFormaPago[];
      this.serviciosArr.forEach((service) => {
        const servicioCajeros = data.filter(
          (register) =>
            register.servicio == service.nombreReportes &&
            !register.medio.startsWith("Pd") &&
            !register.medio.startsWith("PD"),
          /*
            register.medio != "PD.com" &&
            register.medio != "PdApp" &&
            register.medio != "Pd.comJmasJuarez",
            */
        );
        const servicioApp = data.filter(
          (register) => register.servicio == service.nombreReportes && register.medio == "PdApp",
        );
        const servicioWeb = data.filter(
          (register) => register.servicio == service.nombreReportes && register.medio == "PD.com",
        );
        const servicioExt = data.filter(
          (register) => register.servicio == service.nombreReportes && register.medio == "Pd.comJmasJuarez",
        );
        const servicioAppJJ = data.filter(
          (register) => register.servicio == service.nombreReportes && register.medio == "PdAppJmasJuarez",
        );
        if (servicioCajeros.length > 0) {
          agrupados.push({
            cantidadCheques: servicioCajeros.reduce((r, i) => r + Number(i.cantidadCheques), 0),
            cantidadComisiones: servicioCajeros.reduce((r, i) => r + Number(i.cantidadComisiones), 0),
            cantidadEfectivo: servicioCajeros.reduce((r, i) => r + Number(i.cantidadEfectivo), 0),
            cantidadOperaciones: servicioCajeros.reduce((r, i) => r + Number(i.cantidadOperaciones), 0),
            cantidadTarjeta: servicioCajeros.reduce((r, i) => r + Number(i.cantidadTarjeta), 0),
            medio: "Cajeros",
            servicio: service.nombreReportes,
            totalCheques: servicioCajeros.reduce((r, i) => r + Number(i.totalCheques), 0),
            totalComisiones: servicioCajeros.reduce((r, i) => r + Number(i.totalComisiones), 0),
            totalEfectivo: servicioCajeros.reduce((r, i) => r + Number(i.totalEfectivo), 0),
            totalImporte: servicioCajeros.reduce((r, i) => r + Number(i.totalImporte), 0),
            totalTarjeta: servicioCajeros.reduce((r, i) => r + Number(i.totalTarjeta), 0),
            cantidadCoDi: servicioCajeros.reduce((r, i) => r + Number(i.cantidadCoDi), 0),
            totalCoDi: servicioCajeros.reduce((r, i) => r + Number(i.totalCoDi), 0),
          } as ResumenFormaPago);
        }

        if (servicioApp.length > 0) {
          agrupados.push({
            cantidadCheques: servicioApp.reduce((r, i) => r + Number(i.cantidadCheques), 0),
            cantidadComisiones: servicioApp.reduce((r, i) => r + Number(i.cantidadComisiones), 0),
            cantidadEfectivo: servicioApp.reduce((r, i) => r + Number(i.cantidadEfectivo), 0),
            cantidadOperaciones: servicioApp.reduce((r, i) => r + Number(i.cantidadOperaciones), 0),
            cantidadTarjeta: servicioApp.reduce((r, i) => r + Number(i.cantidadTarjeta), 0),
            medio: "PdApp",
            servicio: service.nombreReportes,
            totalCheques: servicioApp.reduce((r, i) => r + Number(i.totalCheques), 0),
            totalComisiones: servicioApp.reduce((r, i) => r + Number(i.totalComisiones), 0),
            totalEfectivo: servicioApp.reduce((r, i) => r + Number(i.totalEfectivo), 0),
            totalImporte: servicioApp.reduce((r, i) => r + Number(i.totalImporte), 0),
            totalTarjeta: servicioApp.reduce((r, i) => r + Number(i.totalTarjeta), 0),
            cantidadCoDi: servicioApp.reduce((r, i) => r + Number(i.cantidadCoDi), 0),
            totalCoDi: servicioApp.reduce((r, i) => r + Number(i.totalCoDi), 0),
          } as ResumenFormaPago);
        }

        if (servicioWeb.length > 0) {
          agrupados.push({
            cantidadCheques: servicioWeb.reduce((r, i) => r + Number(i.cantidadCheques), 0),
            cantidadComisiones: servicioWeb.reduce((r, i) => r + Number(i.cantidadComisiones), 0),
            cantidadEfectivo: servicioWeb.reduce((r, i) => r + Number(i.cantidadEfectivo), 0),
            cantidadOperaciones: servicioWeb.reduce((r, i) => r + Number(i.cantidadOperaciones), 0),
            cantidadTarjeta: servicioWeb.reduce((r, i) => r + Number(i.cantidadTarjeta), 0),
            medio: "PD.Com",
            servicio: service.nombreReportes,
            totalCheques: servicioWeb.reduce((r, i) => r + Number(i.totalCheques), 0),
            totalComisiones: servicioWeb.reduce((r, i) => r + Number(i.totalComisiones), 0),
            totalEfectivo: servicioWeb.reduce((r, i) => r + Number(i.totalEfectivo), 0),
            totalImporte: servicioWeb.reduce((r, i) => r + Number(i.totalImporte), 0),
            totalTarjeta: servicioWeb.reduce((r, i) => r + Number(i.totalTarjeta), 0),
            cantidadCoDi: servicioWeb.reduce((r, i) => r + Number(i.cantidadCoDi), 0),
            totalCoDi: servicioWeb.reduce((r, i) => r + Number(i.totalCoDi), 0),
          } as ResumenFormaPago);
        }

        if (servicioExt.length > 0) {
          agrupados.push({
            cantidadCheques: servicioExt.reduce((r, i) => r + Number(i.cantidadCheques), 0),
            cantidadComisiones: servicioExt.reduce((r, i) => r + Number(i.cantidadComisiones), 0),
            cantidadEfectivo: servicioExt.reduce((r, i) => r + Number(i.cantidadEfectivo), 0),
            cantidadOperaciones: servicioExt.reduce((r, i) => r + Number(i.cantidadOperaciones), 0),
            cantidadTarjeta: servicioExt.reduce((r, i) => r + Number(i.cantidadTarjeta), 0),
            medio: "Pd.comJmasJuarez",
            servicio: service.nombreReportes,
            totalCheques: servicioExt.reduce((r, i) => r + Number(i.totalCheques), 0),
            totalComisiones: servicioExt.reduce((r, i) => r + Number(i.totalComisiones), 0),
            totalEfectivo: servicioExt.reduce((r, i) => r + Number(i.totalEfectivo), 0),
            totalImporte: servicioExt.reduce((r, i) => r + Number(i.totalImporte), 0),
            totalTarjeta: servicioExt.reduce((r, i) => r + Number(i.totalTarjeta), 0),
            cantidadCoDi: servicioExt.reduce((r, i) => r + Number(i.cantidadCoDi), 0),
            totalCoDi: servicioExt.reduce((r, i) => r + Number(i.totalCoDi), 0),
          } as ResumenFormaPago);
        }

        if (servicioAppJJ.length > 0) {
          agrupados.push({
            cantidadCheques: servicioAppJJ.reduce((r, i) => r + Number(i.cantidadCheques), 0),
            cantidadComisiones: servicioAppJJ.reduce((r, i) => r + Number(i.cantidadComisiones), 0),
            cantidadEfectivo: servicioAppJJ.reduce((r, i) => r + Number(i.cantidadEfectivo), 0),
            cantidadOperaciones: servicioAppJJ.reduce((r, i) => r + Number(i.cantidadOperaciones), 0),
            cantidadTarjeta: servicioAppJJ.reduce((r, i) => r + Number(i.cantidadTarjeta), 0),
            medio: "PdAppJmasJuarez",
            servicio: service.nombreReportes,
            totalCheques: servicioAppJJ.reduce((r, i) => r + Number(i.totalCheques), 0),
            totalComisiones: servicioAppJJ.reduce((r, i) => r + Number(i.totalComisiones), 0),
            totalEfectivo: servicioAppJJ.reduce((r, i) => r + Number(i.totalEfectivo), 0),
            totalImporte: servicioAppJJ.reduce((r, i) => r + Number(i.totalImporte), 0),
            totalTarjeta: servicioAppJJ.reduce((r, i) => r + Number(i.totalTarjeta), 0),
            cantidadCoDi: servicioAppJJ.reduce((r, i) => r + Number(i.cantidadCoDi), 0),
            totalCoDi: servicioAppJJ.reduce((r, i) => r + Number(i.totalCoDi), 0),
          } as ResumenFormaPago);
        }
      });

      return agrupados;
    }
    return data;
  }

  private agruparServicios() {
    const servicesSet = new Set(this.formDataVista.map((register) => register.servicio));
    const nFormDataVista = [] as ResumenFormaPago[];

    for (const idx of servicesSet) {
      const serviceArr = this.formDataVista.filter((register) => idx === register.servicio);

      if (serviceArr.length > 0) {
        nFormDataVista.push({
          cantidadCheques: serviceArr.reduce((r, i) => r + Number(i.cantidadCheques), 0),
          cantidadComisiones: serviceArr.reduce((r, i) => r + Number(i.cantidadComisiones), 0),
          cantidadEfectivo: serviceArr.reduce((r, i) => r + Number(i.cantidadEfectivo), 0),
          cantidadOperaciones: serviceArr.reduce((r, i) => r + Number(i.cantidadOperaciones), 0),
          cantidadTarjeta: serviceArr.reduce((r, i) => r + Number(i.cantidadTarjeta), 0),
          servicio: idx,
          totalCheques: serviceArr.reduce((r, i) => r + Number(i.totalCheques), 0),
          totalComisiones: serviceArr.reduce((r, i) => r + Number(i.totalComisiones), 0),
          totalEfectivo: serviceArr.reduce((r, i) => r + Number(i.totalEfectivo), 0),
          totalImporte: serviceArr.reduce((r, i) => r + Number(i.totalImporte), 0),
          totalTarjeta: serviceArr.reduce((r, i) => r + Number(i.totalTarjeta), 0),
          cantidadCoDi: serviceArr.reduce((r, i) => r + Number(i.cantidadCoDi), 0),
          totalCoDi: serviceArr.reduce((r, i) => r + Number(i.totalCoDi), 0),
        } as ResumenFormaPago);
      }
    }

    this.formDataVista = nFormDataVista;
  }

  public getTotales(): void {
    const tot = {
      cantidadCheques: 0,
      cantidadComisiones: 0,
      cantidadEfectivo: 0,
      cantidadOperaciones: 0,
      cantidadTarjeta: 0,
      medio: "",
      servicio: "",
      totalCheques: 0,
      totalComisiones: 0,
      totalEfectivo: 0,
      totalImporte: 0,
      totalTarjeta: 0,
      cantidadCoDi: 0,
      totalCoDi: 0,
    } as ResumenFormaPago;

    tot.cantidadCheques = this.formDataVista.reduce((r, i) => r + Number(i.cantidadCheques), 0);
    tot.cantidadComisiones = this.formDataVista.reduce((r, i) => r + Number(i.cantidadComisiones), 0);
    tot.cantidadEfectivo = this.formDataVista.reduce((r, i) => r + Number(i.cantidadEfectivo), 0);
    tot.cantidadOperaciones = this.formDataVista.reduce((r, i) => r + Number(i.cantidadOperaciones), 0);
    tot.cantidadTarjeta = this.formDataVista.reduce((r, i) => r + Number(i.cantidadTarjeta), 0);
    tot.totalCheques = this.formDataVista.reduce((r, i) => r + Number(i.totalCheques), 0);
    tot.totalComisiones = this.formDataVista.reduce((r, i) => r + Number(i.totalComisiones), 0);
    tot.totalEfectivo = this.formDataVista.reduce((r, i) => r + Number(i.totalEfectivo), 0);
    tot.totalImporte = this.formDataVista.reduce((r, i) => r + Number(i.totalImporte), 0);
    tot.totalTarjeta = this.formDataVista.reduce((r, i) => r + Number(i.totalTarjeta), 0);
    tot.cantidadCoDi = this.formDataVista.reduce((r, i) => r + Number(i.cantidadCoDi), 0);
    tot.totalCoDi = this.formDataVista.reduce((r, i) => r + Number(i.totalCoDi), 0);

    this.totales = tot;
  }

  public async LoadData(): Promise<void> {
    try {
      this.servicios();
      this.formasPago();
      this.empresas();

      await Dropdowns.GetMunicipios().then(() => {
        this.municipiosArr = Dropdowns.ListaMunicipios() as Municipio[];
        this.municipiosArr.push({
          id: 0,
          idEstado: 0,
          nombre: "Internet",
          created: Date.now().toString(),
          updated: Date.now().toString(),
        });
        this.municipiosArr.sort((a, b) => a.nombre.localeCompare(b.nombre));
      });
    } catch (e) {
      const err = e as ApiErrorResponse;
      Snackbar.Show(err.apiError.mensajeUsuario);
    }
  }

  public cajeros(): void {
    this.formFiltro.kioskos = [];
    if (this.formFiltro.municipios) {
      Dropdowns.GetKioskosMunicipio(this.formFiltro.municipios).then(() => {
        this.cajerosArr = (Dropdowns.ListaKioskos() as Kiosko[]).sort((a, b) =>
          a.nombreReportes.localeCompare(b.nombreReportes),
        );
        this.cajerosArr = this.cajerosArr.filter(
          (o) =>
            o.mostrarReportes ||
            (o.ubicacion == "WEB" && !o.nombreReportes.startsWith("Caj") && !o.nombreReportes.endsWith("Ext")),
          /*
            o.nombreReportes == "PD.com" ||
            o.nombreReportes == "PdApp" ||
            o.nombreReportes == "Pd.comJmasJuarez",
            */
        );
        const user = this.$store.getters["oidcStore/oidcUser"];
        if (user["user_type"] != "Empleado") {
          this.cajerosArr = this.cajerosArr.filter((c) => c.idEmpresa == user["RSP.Empresa"]);
          this.formFiltro.kioskos?.push(this.cajerosArr[0].id);
        } else {
          this.cajerosArr.forEach((c) => {
            this.formFiltro.kioskos?.push(c.id);
          });
        }
      });
    }
  }

  public totalPagos(): number {
    return this.formDataVista.reduce((r, i) => r + Number(i.cantidadOperaciones), 0);
  }

  public totalImportes(): number {
    return this.formDataVista.reduce((r, i) => r + Number(i.totalImporte), 0);
  }

  public icon(): string {
    if (this.formFiltro.servicios != undefined) {
      if (this.formFiltro.servicios?.length == 0) {
        this.mensaje = "Todos los Servicios";
        return this.IconMinusCh;
      }
      if (this.formFiltro.servicios?.length > 0) {
        this.mensaje = "Todos los Servicios";
        return this.IconCheckbox;
      }
    }
    this.mensaje = "Seleccione servicios";
    return this.IconCloseCh;
  }

  public toggle(): void {
    if (this.formFiltro.servicios?.length === this.serviciosArr.length) {
      this.formFiltro.servicios = [];
    } else {
      this.formFiltro.servicios = this.serviciosArr.map((service) => {
        return service.id;
      });
    }
    this.mensaje = "Todos los Servicios";
  }

  public toggleMunicipios(): void {
    if (this.formFiltro.municipios?.length === this.municipiosArr.length) {
      this.formFiltro.municipios = [];
    } else {
      this.formFiltro.municipios = this.municipiosArr.map((municipio) => {
        return municipio.id;
      });
    }
    this.cajeros();
  }

  public iconKioskos(): string {
    if (this.formFiltro.kioskos != undefined) {
      if (this.formFiltro.kioskos.length == 0) {
        return this.IconMinusCh;
      }
      if (this.formFiltro.kioskos.length > 0) {
        return this.IconCheckbox;
      }
    }
    return this.IconCloseCh;
  }

  public iconMunicipios(): string {
    if (this.formFiltro.municipios && this.formFiltro.municipios?.length <= 0) {
      return this.IconMinusCh;
    }
    if (this.formFiltro.municipios && this.formFiltro.municipios?.length > 0) {
      return this.IconCheckbox;
    }
    return this.IconCloseCh;
  }

  public returnHeaders(): TableHeader[] {
    if (!this.agruparS) {
      const headers: TableHeader[] = [
        {
          text: "Servicio",
          value: "servicio",
          align: "center",
          sortable: true,
        },
        {
          text: "Medio",
          value: "cajero",
          align: "center",
          sortable: true,
        },
        {
          text: "Total Operaciones",
          value: "totalPagos",
          align: "center",
          sortable: true,
        },
        {
          text: "Importe Total",
          value: "totalImporte",
          align: "center",
          sortable: true,
        },
        {
          text: "Cantidad Efectivo",
          value: "totalEfectivoCantidad",
          align: "center",
          sortable: false,
          width: "8.3%",
        },
        {
          text: "Total Efectivo",
          value: "totalEfectivo",
          align: "center",
          sortable: true,
          width: "8.3%",
        },
        {
          text: "Cantidad Tarjeta",
          value: "totalTarjetaCantidad",
          align: "center",
          sortable: false,
          width: "8.3%",
        },
        {
          text: "Total Tarjeta",
          value: "totalTarjeta",
          align: "center",
          sortable: true,
          width: "8.3%",
        },
        {
          text: "Cantidad Cheques",
          value: "totalCuentaChequesCantidad",
          align: "center",
          sortable: false,
          width: "8.3%",
        },
        {
          text: "Total Cheques",
          value: "totalCuentaCheques",
          align: "center",
          sortable: true,
          width: "8.3%",
        },
        {
          text: "Cantidad CoDi",
          value: "totalCodiCantidad",
          align: "center",
          sortable: false,
          width: "8.3%",
        },
        {
          text: "Total CoDi",
          value: "totalImporteCodi",
          align: "center",
          sortable: true,
          width: "8.3%",
        },
      ];
      return headers;
    }
    const headers: TableHeader[] = [
      {
        text: "Servicio",
        value: "servicio",
        align: "center",
        sortable: true,
      },
      {
        text: "Total Operaciones",
        value: "totalPagos",
        align: "center",
        sortable: true,
      },
      {
        text: "Importe Total",
        value: "totalImporte",
        align: "center",
        sortable: true,
      },
      {
        text: "Cantidad Efectivo",
        value: "totalEfectivoCantidad",
        align: "center",
        sortable: false,
        width: "8.3%",
      },
      {
        text: "Total Efectivo",
        value: "totalEfectivo",
        align: "center",
        sortable: true,
        width: "8.3%",
      },
      {
        text: "Cantidad Tarjeta",
        value: "totalTarjetaCantidad",
        align: "center",
        sortable: false,
        width: "8.3%",
      },
      {
        text: "Total Tarjeta",
        value: "totalTarjeta",
        align: "center",
        sortable: true,
        width: "8.3%",
      },
      {
        text: "Cantidad Cheques",
        value: "totalCuentaChequesCantidad",
        align: "center",
        sortable: false,
        width: "8.3%",
      },
      {
        text: "Total Cheques",
        value: "totalCuentaCheques",
        align: "center",
        sortable: true,
        width: "8.3%",
      },
      {
        text: "Cantidad CoDi",
        value: "totalCodiCantidad",
        align: "center",
        sortable: false,
        width: "8.3%",
      },
      {
        text: "Total CoDi",
        value: "totalImporteCodi",
        align: "center",
        sortable: true,
        width: "8.3%",
      },
    ];
    return headers;
  }

  public toggleKioskos(): void {
    if (this.formFiltro.kioskos?.length === this.cajerosArr.length) {
      this.formFiltro.kioskos = [];
    } else {
      this.formFiltro.kioskos = this.cajerosArr.map((cajero) => {
        return cajero.id;
      });
    }
  }

  public empresas(): void {
    const user = this.$store.getters["oidcStore/oidcUser"];
    if (user["user_type"] == "Empleado") {
      if ((Dropdowns.ListaEmpresas() as Empresa[]).length == 0) {
        Dropdowns.GetEmpresas()
          .then(() => {
            this.empresasArr = Dropdowns.ListaEmpresas() as Empresa[];
            this.empresasArr.sort((a, b) => a.nombre.localeCompare(b.nombre));
          })
          .catch((resp) => {
            const err: ApiErrorResponse = resp as ApiErrorResponse;
            Snackbar.Show(err.apiError.mensajeUsuario);
            Spinner.Hide();
          });
      } else {
        this.empresasArr = Dropdowns.ListaEmpresas() as Empresa[];
      }
    } else {
      this.clienteSP()
        .GetEmpresa(user["RSP.Empresa"])
        .then((resp) => {
          this.empresasArr.push(resp as Empresa);
          this.formFiltro.idEmpresa = this.empresasArr[0].id;
          this.servicios();
        })
        .catch((resp) => {
          const err: ApiErrorResponse = resp as ApiErrorResponse;
          Snackbar.Show(
            err.apiError.mensajeUsuario + " | Faltan configuraciones comuniquese con su proveedor del servicio.",
          );
          this.formFiltro.idEmpresa = "0000";
          Spinner.Hide();
        });
    }
  }

  public onEmpresaChange(): void {
    this.formFiltro.servicios = this.serviciosArr
      .filter((service) => service.idEmpresa === this.formFiltro.idEmpresa)
      .map((service) => {
        return service.id;
      });
  }
}
